import React from 'react';
import Section from '@components/theme/Section';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import Page, { getPageElements } from '@components/Page';
import Teammembers from '@components/Teammembers';
import Footer from '@components/Footer';

export default function AboutUsTemplate({
  data: {
    page,
    teammembers
  },
  pageContext: { language, refs, layouts, configuration }
}) {
  const { teamTitle, teamMembersTitle, teamDescription, teamJoin, teamPhoto, teamMission } = getPageElements(page);
  return (
    <Page>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <div className="bg-primary pt-4 pt-lg-7">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-5">
              <span className="badge badge-dark">{teamMission}</span>
              <div className="my-3">
                <span className="h1">{teamTitle}</span>
              </div>
              <div
                className="lead"
                dangerouslySetInnerHTML={{
                  __html: teamDescription
                }}
              />
            </div>
            <div className="col-12 col-xl-7">
              <GatsbyImage
                css={css`
                  border-top-left-radius: 1rem;
                  border-top-right-radius: 1rem;
                `}
                fluid={teamPhoto.localFile.sharp.fluid}
              />
            </div>
          </div>
        </div>
      </div>

      <Section>
        <div className="container py-5">
          <h2 className="h1">{teamMembersTitle}</h2>
          <div
            className="mt-3 mb-4"
            css={css`
              background: var(--primary);
              width: 70px;
              height: 10px;
            `}
          />
        </div>

        <Teammembers teammembers={teammembers} />
      </Section>
      <Section className="bg-primary-alt" dividerTop={{ variant: 3 }}>
        <div
          css={css`
            text-align: center;

            h1,
            h2 {
              font-weight: bold;
              font-size: 40px;
            }
            p {
              color: var(--primary);
            }

            font-size: 40px;
          `}
          dangerouslySetInnerHTML={{ __html: teamJoin }}
        />
      </Section>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String, $lang: WpLanguageCodeEnum) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    teammembers: allWpTeamMember(filter: {teamMember: {active: {eq: true}}, language: {code: {eq: $lang}}}) {
      nodes {
        ...Teammembers
      }
    }
  }
`;