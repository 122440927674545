import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import { orderBy } from 'lodash';

const Title = styled.div`
  font-size: 25px;
  font-style: italic;
  margin-bottom: 0.5rem;
`;

const ImageCss = css`
  position: relative;
  overflow: hidden;
  border-radius: 170px;
  width: 170px;
  min-width: 170px;
`;

const Description = styled.div`
  margin: 0.5rem 0;
  h3 {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  p {
    text-align: justify;
  }
`;

export default function Teammembers({ teammembers }) {
  return (
    <div className="container">
      <div className="row">
        {orderBy(teammembers.nodes, ['teamMember.order'], ['asc']).map(({ teamMember }, i) => (
          <div
            className={`mb-4 col-12 col-md-6 ${i % 2 ? 'pl-md-5' : 'pr-md-5'
            }`}
            key={i}
          >
            <div className="d-flex align-items-baseline justify-content-between">
              <Title>{teamMember.jobTitle}</Title>
              <GatsbyImage
                css={ImageCss}
                fixed={teamMember.photo.localFile.sharp.fixed}
              />
            </div>
            <div
              css={css`
                  width: 150px;
                  height: 1px;
                  background: var(--dark);
                `}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: `<h3>${teamMember.name}</h3>${teamMember.description}`
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export const query = graphql`
  fragment Teammembers on WpTeamMember {
    teamMember {
      active
      description
      featured
      jobTitle
      name
      order
      photo {
        localFile {
          sharp: childImageSharp {
            fixed(width: 170, height: 170) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;